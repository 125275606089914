import React from "react"

interface ListItemProps {
  header: string
  text: string
  video: string
  styles: {
    header: string
    text: string
    video: string
  }
}

const ListItemContent: React.FC<ListItemProps> = ({
  header,
  text,
  video,
  styles,
}) => {
  return (
    <>
      <h2 className={styles.header}>{header}</h2>
      <p className={styles.text}>{text}</p>
      <video controls className={styles.video}>
        <source src={video} />
      </video>
    </>
  )
}

export default ListItemContent
