import Button from "@/components/button"
import ListItem from "@/components/list-item"
import SEO from "@/components/seo"
// @ts-ignore: styles import
import * as styles from "@/styles/scss/components/page-list.module.scss"
import React from "react"
// @ts-ignore: data import
import content from "@/content/todo-plus.json"

console.log({ styles })

type Content = {
  header: string
  text: string
  video: string
}

const replaceWithHeiphens = (str: string) => str.replace(/\s/g, "-")

const TodoPlus = () => {
  return (
    <>
      <SEO title="Todo+"></SEO>
      <div className={styles.page}>
        <div className={styles.menu}>
          <div className={"f-col jc--center"}>
            <h1 className="text--white">Todo+</h1>
          </div>
          <a href="/" className={styles.link}>
            HOME
          </a>
          {content.map((el: Content) => (
            <a
              key={el.header}
              href={"#" + replaceWithHeiphens(el.header)}
              className={styles.link}
            >
              {el.header}
            </a>
          ))}
        </div>
        <main className={styles.content}>
          <div className="gutters">
            <ul className={styles.list}>
              {content.map((el: Content) => (
                <li id={replaceWithHeiphens(el.header)}>
                  <ListItem
                    {...el}
                    styles={{
                      header: styles.header,
                      text: styles.text,
                      video: styles.video,
                    }}
                  ></ListItem>
                </li>
              ))}
            </ul>
          </div>
        </main>
      </div>
    </>
  )
}

export default TodoPlus
